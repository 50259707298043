@import './icon-fonts.scss';

@font-face {
  font-family: 'Satoshi';
  src: url(./fonts/Satoshi-Bold.otf);
  font-weight: 600;
}
@font-face {
  font-family: 'Satoshi';
  src: url(./fonts/Satoshi-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: 'Satoshi';
  src: url(./fonts/Satoshi-Regular.otf);
  font-weight: 400;
}
@font-face {
  font-family: 'Satoshi';
  src: url(./fonts/Satoshi-Light.otf);
  font-weight: 300;
}
